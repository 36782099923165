import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import StoriesMain from "../components/stories-main";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query StoriesPageQuery {
    stories: sanityStories {
      pageHeader {
        title
        description
      }
      stories {
        _key
        heading
        _rawContent
        image {
          ...SanityImage
          alt
        }
        disableReadMore
      }
      callToAction {
        title
        button {
          blank
          noArrow
          primaryButton
          text
          url
        }
      }
    }
  }
`;

const StoriesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const stories = (data || {}).stories;

  return (
    <Layout>
      <Seo
        title={stories.pageHeader.title}
        description={stories.pageHeader.description}
      />

      {(stories.pageHeader || (stories.stories && stories.stories.length > 0)) && <StoriesMain pageHeader={stories.pageHeader} content={stories.stories} />}

      {stories.callToAction && <CallToAction {...stories.callToAction} />}
    </Layout>
  );
};

export default StoriesPage;
