import React from "react";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

import * as styles from "../styles/components/stories-content.module.css";

function Content({ content }) {
  return (
    <div className={styles.root} id="content">
      {content.map(({ _key, heading, _rawContent, image, disableReadMore }) => {
        return (
          <div className={cn("grid", styles.contentRow)} key={_key}>
            <div className="col-12 col-md-6">
              {heading && <h2 className={cn("h1", styles.heading)}>{heading}</h2>}
              {image && image.asset && (
                <img
                  src={imageUrlFor(buildImageObj(image))
                    .auto("format")
                    .url()}
                  alt={image.alt}
                  className={styles.image}
                />
              )}
            </div>
            <div className="col-12 col-md-6">
              <div className="content-lg">
                {_rawContent && (
                  <PortableText blocks={_rawContent} />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Content;
