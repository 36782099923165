import React, { useState, useEffect } from "react";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import { Link } from 'react-scroll';

import * as styles from "../styles/components/page-header.module.css";

const PageHeader = (props) => {
  const {
    title,
    description
  } = props;

  // Get viewport size
  const hasWindow = typeof window !== 'undefined';
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }
    }, []);
    return windowDimensions;
  }
  const { width } = useWindowDimensions();

  return (
    <div className={styles.root} id="page-header">
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-md">
            {title && (<h1 className={styles.title}>{title}</h1>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg content-m-xxxs">
            {description && (<p className="h1">{description}</p>)}
            <Link 
              to="content"
              spy={false}
              smooth={true}
              hashSpy={false}
              offset={width >= 1200 ? -324 : width >= 960 && width < 1200 ? -244 : -218}
              duration={800}
              delay={0}
              isDynamic={true}
              ignoreCancelEvents={false}
              spyThrottle={0}
              className={styles.btnScroll}
            >
              <Icon symbol="scroll" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
