import React, { useLayoutEffect } from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import * as styles from "../styles/components/call-to-action.module.css";

const CallToAction = (props) => {

  const {
    title,
    button
  } = props;

  gsap.registerPlugin(ScrollTrigger);
  const tl = gsap.timeline();

  useLayoutEffect(() => {
    setTimeout(() => {
      tl.to('#cta-bg', {
        opacity: 1,
        ease: "none",
      });

      tl.to('#cta-bg', {
        scrollTrigger: {
          trigger: '#cta',
          start: "top bottom", // the default values
          end: "bottom top",
          scrub: true
        },
        yPercent: 25,
        ease: 'none',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.root} id="cta">
      <Container>
        <div className={styles.bg} id="cta-bg">
          <div className="m-hide">
            <img src="/images/DNA_callout.png" alt="" />
          </div>
          <div className="d-hide">
            <img src="/images/DNA_callout_mobile.png" alt="" />
          </div>
        </div>
        <div className="grid">
          <div className="col-12 col-md-6">
            <div className="content-lg">
              {title && (<h5 className="h1">{title}</h5>)}
              {button && button.url && (
                <>
                  {button.blank ? (
                    <a href={button.url} className={cn("btn", button.primaryButton && "primary", button.noArrow && "center", styles.ctaBtn)} target="_blank" rel="noreferrer">
                        {button.text}
                        {!button.noArrow && (<Icon symbol="arrow-right" />)}
                    </a>
                  ) : (
                    <Link to={button.url} className={cn("btn", button.primaryButton && "primary", button.noArrow && "center", styles.ctaBtn)}>
                      {button.text}
                      {!button.noArrow && (<Icon symbol="arrow-right" />)}
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CallToAction;
