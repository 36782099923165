import React from "react";
import "../styles/common/index.css";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";

const Layout = ({ children }) => (
  <>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </>
);

export default Layout;
